import { INaeSchema } from "@newageerp/v3.app.main-bundle"
export const NaeSSchema: INaeSchema[] = [
    {
        "className": "CartData",
        "schema": "cart-data",
        "title": "Cart",
        "titlePlural": "Carts",
        "required": [],
        "scopes": []
    },
    {
        "className": "CartUpsell",
        "schema": "cart-upsell",
        "title": "Upsell",
        "titlePlural": "Upsells",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder",
        "title": "Explore data folder",
        "titlePlural": "Explore data folder",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item",
        "title": "Explore data",
        "titlePlural": "Explore data",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm",
        "title": "Key-value",
        "titlePlural": "Key-value",
        "required": [],
        "scopes": []
    },
    {
        "className": "User",
        "schema": "user",
        "title": "",
        "titlePlural": "",
        "required": [],
        "scopes": []
    }
]
        export const NaeSSchemaMap = {
    "CartData": {
        "className": "CartData",
        "schema": "cart-data"
    },
    "SfKeyValueOrm": {
        "className": "SfKeyValueOrm",
        "schema": "sf-key-value-orm"
    },
    "CartUpsell": {
        "className": "CartUpsell",
        "schema": "cart-upsell"
    },
    "User": {
        "className": "User",
        "schema": "user"
    },
    "SfExploreDataItem": {
        "className": "SfExploreDataItem",
        "schema": "sf-explore-data-item"
    },
    "SfExploreDataFolder": {
        "className": "SfExploreDataFolder",
        "schema": "sf-explore-data-folder"
    }
}