
import React, { Fragment } from 'react'
import { PropsId, PropsLink } from './types';
import moment from "moment";
import { SelectorCartDataNae, SelectorCartUpsellNae } from '../models/ormSelectors';
import { useCartDataHookNae } from '../hooks/useCartDataHookNae';
import { useCartUpsellHookNae } from '../hooks/useCartUpsellHookNae';


export const useEmptyHook = (id: number) => {
            return undefined;
        }

export const getHookForSchema = (schema: string) => {
  let selector : any = useEmptyHook;
  if (schema === 'cart-data') {
    return useCartDataHookNae;
  }
  if (schema === 'cart-upsell') {
    return useCartUpsellHookNae;
  }
  return selector;
}

export function CartDataEmptyFieldNae(props: PropsLink) {
  const element = useCartDataHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

export function CartUpsellEmptyFieldNae(props: PropsLink) {
  const element = useCartUpsellHookNae(props.id ? props.id : -1);
  if (!element) {
      return <Fragment />
  }

  return <Fragment/>
}

