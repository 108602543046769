import { ICartDataFieldsNae } from './useCartDataHookNae';
import { ICartUpsellFieldsNae } from './useCartUpsellHookNae';
import { SelectorCartDataNae,SelectorCartUpsellNae, } from "../models/ormSelectors"
import { NaeSSchemaMap } from '../config/NaeSSchema';
import { store } from '../models/ormstore';

export const getDataCacheForSchema = (className: string) => {
    if (className === NaeSSchemaMap.CartData.className) {
            return {
                dataToCheck: SelectorCartDataNae(store.getState()),
                fields: ICartDataFieldsNae,    
            }
        }
    if (className === NaeSSchemaMap.CartUpsell.className) {
            return {
                dataToCheck: SelectorCartUpsellNae(store.getState()),
                fields: ICartUpsellFieldsNae,    
            }
        }
        return undefined;
}