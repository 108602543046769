import { createSelector } from "redux-orm";
import orm from "./orm";

export const SelectorCartDataNae = createSelector(orm.CartDataModel);
export const SelectorCartUpsellNae = createSelector(orm.CartUpsellModel);
export const SelectorQueueNae = createSelector(orm.QueueModel);

export const selectorBySchemaClassName = (schema) => {
        if (schema === 'CartData') {
        return SelectorCartDataNae;
    }
        if (schema === 'CartUpsell') {
        return SelectorCartUpsellNae;
    }
        if (schema === 'Queue') {
        return SelectorQueueNae;
    }
    }

export const selectorBySchemaSlug = (schema) => {
        if (schema === 'cart-data') {
        return SelectorCartDataNae;
    }
        if (schema === 'cart-upsell') {
        return SelectorCartUpsellNae;
    }
        if (schema === '') {
        return SelectorQueueNae;
    }
    }