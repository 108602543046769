import { CustomEditComponentsMap } from "../_generated/_custom/edit/CustomEditComponentsMap"
import { CustomViewComponentsMap } from "../_generated/_custom/view/CustomViewComponentsMap"
import { CustomListComponentsMap } from "../_generated/_custom/tabs/CustomListComponentsMap"
import { CartUpsellConfigSchema } from "./ConfigSchema/CartUpsellConfigSchema"

export const PluginsMap: any = {
    ...CustomEditComponentsMap,
    ...CustomViewComponentsMap,
    ...CustomListComponentsMap,

    config: {
        cartUpsell: CartUpsellConfigSchema,
    }
}