import { ORM } from "redux-orm";
import CartDataModel from "./CartDataModel"
import CartUpsellModel from "./CartUpsellModel"
import QueueModel from "./QueueModel"

export const orm = new ORM({
    stateSelector: (state) => {
        return state.orm; // wherever the reducer is put during createStore
    },
});
orm.register(
    CartDataModel,
    CartUpsellModel,
    QueueModel,
);

export default orm;